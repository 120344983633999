import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ICallToAction, ITrustIcon } from 'wz-types/home-page';
import { IInitialData } from 'wz-types/page-data';
import { FirestoreRefs, Globals } from '~shared/classes';
import { wzCatchObservableError } from '~shared/services';



@Injectable({
  providedIn: 'root'
})
export class HomePageService {
  fileName = 'home-page.service.ts';
  collectioName = 'homePage';
  docIds = {
    primaryCTA: 'primaryCallToAction',
    secondaryCTA: 'secondaryCallToAction',
    trustIcons: 'trustIcons'
  };

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient
  ) {
  }

  updateHomePageCache(refreshCache: boolean): Observable<IInitialData> {
    const url = `${Globals.environment.apiUrl}page-data/init?refreshCache=${refreshCache}`;
    return this.http.get(url).pipe(
      wzCatchObservableError(this.fileName, 'updateHomePageCache()')
    );
  }

  savePrimaryCallToAction(primaryCallToAction: ICallToAction, isUpdate?: boolean): Observable<void> {
    primaryCallToAction.id = this.docIds.primaryCTA;
    const method = isUpdate ? 'update' : 'set';
    return from(FirestoreRefs.homePage.doc(this.docIds.primaryCTA)[method](primaryCallToAction)).pipe(
      mergeMap(() => this.updateHomePageCache(true)),
      map(() => undefined),
      wzCatchObservableError(this.fileName, 'savePrimaryCallToAction()')
    );
  }

  saveSecondaryCallToAction(secondaryCta: ICallToAction, isUpdate?: boolean): Observable<void> {
    secondaryCta.id = this.docIds.secondaryCTA;
    const method = isUpdate ? 'update' : 'set';
    return from(FirestoreRefs.homePage.doc(this.docIds.secondaryCTA)[method](secondaryCta)).pipe(
      mergeMap(() => this.updateHomePageCache(true)),
      map(() => undefined),
      wzCatchObservableError(this.fileName, 'saveSecondaryCallToAction()')
    );
  }

  updateBoxColor(hexId: string) {
    return forkJoin([
      from(FirestoreRefs.homePage.doc(this.docIds.secondaryCTA).update({ boxColor: hexId })),
      from(FirestoreRefs.homePage.doc(this.docIds.primaryCTA).update({ boxColor: hexId }))
    ]).pipe(
      mergeMap(() => this.updateHomePageCache(true)),
      wzCatchObservableError(this.fileName, 'updateBoxColor()')
    );
  }

  getCallsToAction(): Observable<[ICallToAction, ICallToAction]> {
    const getDocsObsArray = [this.docIds.primaryCTA, this.docIds.secondaryCTA].map((docId: string) => FirestoreRefs.homePage.doc(docId).get());
    return combineLatest(getDocsObsArray).pipe(
      map((snaps: any[]) => <any>snaps.map(s => s.data())),
      wzCatchObservableError(this.fileName, 'getCallsToAction()')
    );
  }

  saveTrustIcons(icons: ITrustIcon[], isUpdate?: boolean): Observable<void> {
    const method = isUpdate ? 'update' : 'set';
    return from(FirestoreRefs.homePage.doc(this.docIds.trustIcons)[method]({
      id: this.docIds.trustIcons,
      icons
    })).pipe(
      mergeMap(() => this.updateHomePageCache(true)),
      map(() => undefined),
      wzCatchObservableError(this.fileName, 'saveTrustIcons()')
    );
  }

  getTrustIcons(): Observable<[ITrustIcon, ITrustIcon, ITrustIcon]> {
    return from(FirestoreRefs.homePage.doc(this.docIds.trustIcons).get()).pipe(
      map((s: any) => {
        let icons;
        const doc = s.data();
        if (!!doc && !!doc.icons) icons = doc.icons;
        return icons;
      }),
      wzCatchObservableError(this.fileName, 'getTrustIcons()')
    );
  }

  getAboutSectionBackgroundImgUrl(): Observable<string> {
    return from(FirestoreRefs.homePage.doc('aboutSection').get()).pipe(
      map((r: any) => !!r.data() ? r.data().backgroundImgUrl : undefined),
      wzCatchObservableError(this.fileName, 'getAboutSectionBackgroundImgUrl()')
    );
  }

  saveAboutSectionBackgroundImgUrl(backgroundImgUrl: string): Observable<void> {
    return from(FirestoreRefs.homePage.doc('aboutSection').update({ backgroundImgUrl })).pipe(
      mergeMap(() => this.updateHomePageCache(true)),
      map(() => undefined),
      wzCatchObservableError(this.fileName, 'saveAboutSectionBackgroundImgUrl()')
    );
  }
}
