import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { IAdminOrderDetail } from 'wz-types/orders';
import { Globals } from '~shared/classes';
import { OrdersStore } from '~shared/stores';


@Component({
  selector: 'wz-admin-order-detail',
  templateUrl: './admin-order-detail.component.html',
  styleUrls: ['./admin-order-detail.component.scss']
})

export class AdminOrderDetailComponent implements OnInit {
  @Input() orderId: string;
  o: IAdminOrderDetail;

  isLoading = false;
  url = Globals.environment.siteUrl;
  form: FormGroup;

  constructor(
    private ordersStore: OrdersStore,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      adminNotes: [undefined, [Validators.maxLength(1000)]]
    });

    this.ordersStore.getAdminOrderDetail(this.orderId).pipe(
      map((order: IAdminOrderDetail) => {
        this.o = order;

        this.getCtrl('adminNotes').setValue(order.adminNotes);

        setTimeout(() => {
          const el = document.getElementById(order.id);
          el.scrollIntoView();
        }, 500);
      }),
      take(1)
    ).subscribe();
  }

  getCtrl(ctrlName: string) {
    return this.form.get(ctrlName);
  }

  public saveAdminNotes(): void {
    this.isLoading = true;
    this.ordersStore.dispatch('updateAdminNotes', this.o.id, this.form.get('adminNotes').value).pipe(
      map(res => {
        this.isLoading = false;
      })).subscribe();
  }
}
