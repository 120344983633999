<ng-container *ngIf="hasAllListings">
  <ng-container *ngIf="order as o">
    <div class="seller-order-container"  [ngSwitch]="!!isOnSellerPage">
      <div class="seller-order-header">
        <h2><b>Order Date:</b> {{o.createdTimestamp | date}}</h2>       
      </div>
      <div class="seller-order-body">
        <div class="seller-order-body-top">
          <h2>
            #{{o.id | orderId}} - 
            <b>
              {{orderTracking.formattedStatus}}
              <wz-tooltip [matTooltip]="orderTracking.tooltipVerbiage" (click)="$event.stopPropagation()">
                <mat-icon style="font-size:1.2em; margin-left: 0; cursor: pointer; margin-top: 5px;" color="accent">help</mat-icon>
              </wz-tooltip>
            </b>
          </h2>
          <div class="hide-mobile">
            <ng-container *ngSwitchCase="true" [ngSwitch]="isAllCustom">
              <ng-container *ngSwitchCase="false">
                <button 
                  class="btn btn-pink btn-sm" 
                  *ngIf="!o.trackingNumber && !o.carrier && !hasLabelsForItems(o)"
                  [routerLinkAbsolute]="'seller/orders/get-labels/' + o.id + '/' + o.billToAddressZip"
                  (click)="$event.stopPropagation()"
                >
                  Get shipping labels
                </button>
                <button 
                  class="btn btn-pink btn-sm" 
                  *ngIf="!o.trackingNumber && !o.carrier && hasLabelsForItems(o)"
                  [routerLinkAbsolute]="'seller/orders/print-labels/' + o.id"
                  (click)="$event.stopPropagation()"
                >
                  Print shipping labels
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <h2>Ship all item(s) to buyer.</h2>
              </ng-container>
            </ng-container>
          </div>
        </div> 
        <div class="seller-order-body-bottom">
          <div class="seller-order-summery-row">
            <div class="seller-order-summery-details">
              <h2><b>Order Details</b> - <b>Total :</b> ${{o.totalsBilled.totalWithShippingAndTax | number:'1.2-2'}}</h2>
              <div (click)="$event.stopPropagation();" style="color: red; font-size: small; padding-top: 4px;" *ngIf="hasCancelledItems">
                <mat-icon style="font-size: 1em; margin-top: 6px;">error_outline</mat-icon>
                <span style="margin-left: -10px;">Has cancelled item(s)</span>
                <button (click)="hideOrder(o.id)" class="btn btn-pink-border btn-sm" style="margin-left: 10px;">
                  Hide Order
                </button>
                </div>
              <ng-container *ngSwitchCase="true">
                <div class="order-item-summery" *ngFor="let i of o?.items">
                  <div class="order-item-image">
                    <img matListAvatar style="border-radius: 0!important;" [src]="getThumbnailImg(i.listingId)" alt="Item image">
                  </div>
                  <div class="order-item-content">
                    <h4>{{ getListingName(i.listingId)}}</h4>
                    <p class="show-mobile">${{ i.taxjar.order_total_amount | number:'1.2-2' }}</p>
                    <p>Qty: {{ i.qty }}</p>
                    <p *ngIf="!!i.customizationNotes" [matTooltip]="i.customizationNotes">
                      <b>Customization notes</b>
                      {{i.customizationNotes}}
                  </div>
                  <div class="order-item-price hide-mobile">
                    <h4>${{ i.taxjar.order_total_amount | number:'1.2-2' }}</h4>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="seller-order-summery-action">
              <div class="show-mobile">
                <ng-container *ngSwitchCase="true" [ngSwitch]="isAllCustom">
                  <ng-container *ngSwitchCase="false">
                    <button 
                      class="btn btn-pink btn-sm" 
                      *ngIf="!o.trackingNumber && !o.carrier && !hasLabelsForItems(o)"
                      [routerLinkAbsolute]="'seller/orders/get-labels/' + o.id + '/' + o.billToAddressZip"
                      (click)="$event.stopPropagation()"
                    >
                      Get shipping labels
                    </button>
                    <button 
                      class="btn btn-pink btn-sm" 
                      *ngIf="!o.trackingNumber && !o.carrier && hasLabelsForItems(o)"
                      [routerLinkAbsolute]="'seller/orders/print-labels/' + o.id"
                      (click)="$event.stopPropagation()"
                    >
                      Print shipping labels
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchCase="true">
                    <h2>Ship all item(s) to buyer.</h2>
                  </ng-container>
                </ng-container>
              </div>
              <button class="btn btn-pink btn-sm"
               *ngIf="isOnSellerPage && (!hasLabelsForItems(o) || (o.trackingNumber && o.carrier))"
               [routerLinkAbsolute]="'seller/orders/tracking-info/' + o.id + '/' + o.billToAddressZip"
               (click)="$event.stopPropagation()"
               >
                {{(o.trackingNumber && o.carrier) ? 'Tracking Info' : 'Ship it myself'}}
              </button>
              <button class="btn btn-pink-border btn-sm">
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
